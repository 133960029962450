enum FeatureFlags {
  KPS_HARDWARE = 'KPS_HARDWARE',
  MENU_SYSTEM_SELECTION = 'MENU_SYSTEM_SELECTION',
  MENU_SYSTEM_SELECTION_UPDATES = 'MENU_SYSTEM_SELECTION_UPDATES',
  DRIVE_THRU_EXPRESS = 'DRIVE_THRU_EXPRESS',
  PROFILES = 'PROFILES',
  DEVICE_GROUP_PRIORITY = 'DEVICE_GROUP_PRIORITY',
  PRINT_CUSTOMER_RECEIPT = 'PRINT_CUSTOMER_RECEIPT',
  DELIVERY_AREA = 'DELIVERY_AREA',
  MMS_LICENSEE = 'MMS_LICENSEE',
  AUTO_CHECK_IN = 'AUTO_CHECK_IN',
  DEVICE_GROUP_ITEM_PRIORITY = 'DEVICE_GROUP_ITEM_PRIORITY',
  BEVERAGE_RECEIPT = 'BEVERAGE_RECEIPT',
  CFA_DELIVERY_ON_OFF = 'CFA_DELIVERY_ON_OFF',
  THIRD_P_DELIVERY = '3P_DELIVERY',
  SUPER_LOCKS = 'SUPER_LOCKS',
  CFA_DELIVERY_HOURS = 'CFA_DELIVERY_HOURS',
  MAINTENANCE_MODE = 'MAINTENANCE_MODE',
  BEVERAGE_HIGHLIGHTING = 'BEVERAGE_HIGHLIGHTING_(KPS)',
  VENUES = 'VENUES',
  INGREDIENT_BASED_LOCKS = 'INGREDIENT-BASED_LOCKS',
  LIMITED_MENUS = 'LIMITED_MENUS',
  CUSTOM_RECEIPT_TEXT = 'CUSTOM_RECEIPT_TEXT',
  BEVERAGE_AND_TREAT_LABELS = 'BEVERAGE_AND_TREAT_LABELS',
  SMALL_PRINTER_PAPER = '58MM_RECEIPT_PAPER',
  VIEWPOINT_PROFILES = 'VIEWPOINT_PROFILES',
  MAXIMUM_CURBSIDE_ORDERS = 'MAXIMUM_CURBSIDE_ORDERS',
  SCHEDULED_CHANGES = 'SCHEDULED_CHANGES',
  LOCAL_ITEMS = 'LOCAL_ITEMS',
  THIRD_P_STATUS_PAGE = '3P_STATUS_PAGE',
  VIEWPOINT = 'VIEWPOINT',
  SAHARA_FRY = 'SAHARA_FRY',
  VIEWPOINT_MENU_SORTING = 'VIEWPOINT_MENU_SORTING',
  TAX_EXEMPT_ACCOUNTS = 'TAX_EXEMPT_ACCOUNTS',
  VIEWPOINT_SERVICE_RECEIPT = 'VIEWPOINT_SERVICE_RECEIPT',
  VIEWPOINT_WHEN_MODIFIED = 'VIEWPOINT_WHEN_MODIFIED',
  VIEWPOINT_CUSTOMER_RECEIPT = 'VIEWPOINT_CUSTOMER_RECEIPT',
  VIEWPOINT_ACTIVITY_LEVELS = 'VIEWPOINT_ACTIVITY_LEVELS',
  VIEWPOINT_ORDER_CARDS = 'VIEWPOINT_ORDER_CARDS',
  VIEWPOINT_ORDER_CARDS_V2 = 'VIEWPOINT_ORDER_CARDS_V2',
  VIEWPOINT_ORDER_DESTINATION = 'VIEWPOINT_ORDER_DESTINATIONS',
  VIEWPOINT_ORDER_STATES = 'VIEWPOINT_ORDER_STATES',
  VIEWPOINT_BUMPS = 'VIEWPOINT_BUMPS',
  VIEWPOINT_LBM = 'VIEWPOINT_LBM',
  VIEWPOINT_GEN3_API = 'VIEWPOINT_GEN3_API',
  VIEWPOINT_DITTO_PRINTING = 'VIEWPOINT_DITTO_PRINTING',
  VIEWPOINT_ALERT_TIMERS = 'VIEWPOINT_ALERT_TIMERS',
  VIEWPOINT_SOS_REPORTS = 'VIEWPOINT_SOS_REPORTS',
  LARGE_FONT_ROUTED_ITEMS = 'LARGE_FONT_-_ROUTED_ITEMS',
  SPFB_CONFIGURATIONS = 'SPFB_CONFIGURATIONS',
  MOBILE_CUSTOMER_LOCATOR = 'MOBILE_CUSTOMER_LOCATOR',
  SEND_3PD_DRIVER_ARRIVAL_NOTIFICATION = 'SEND_3PD_DRIVER_ARRIVAL_NOTIFICATION',
  SEPARATE_FREE_ITEMS = 'SEPARATE_FREE_ITEMS',
  DINE_IN_AUTO_RELEASE = 'DINE_IN_AUTO_RELEASE',
  ENABLE_SEQUENCING_FOR_MOBILE_THRU = 'ENABLE_SEQUENCING_FOR_MOBILE_THRU',
  CUSTOM_RECEIPT_TEXT_LINE_SIX = 'CUSTOM_RECEIPT_TEXT_LINE_SIX',
  CUSTOM_DISCOUNT = 'CUSTOM_DISCOUNT',
  LIMITED_MENUS_CONTINGENCY = 'LIMITED_MENUS_-_CONTINGENCY',
  LIMITED_MENUS_EVENT_DAY = 'LIMITED_MENUS_-_EVENT_DAY',
  SUBSTITUTION_PRICE_OVERRIDES = 'SUBSTITUTION_PRICE_OVERRIDES',
  DISABLE_KPS = 'DISABLE_KPS',
  TIME_RELEASE_FOR_MOBILE_THRU = 'TIME-RELEASE_FOR_MOBILE_THRU',
  ENABLE_CARES_PILOT = 'ENABLE_CARES_PILOT',
  DEVICE_GROUP_CONFIGURATION = 'DEVICE_GROUP_CONFIGURATION',
  CUSTOM_PERMISSIONS = 'CUSTOM_PERMISSIONS',
  PROXY_VENUE_API = 'PROXY_VENUE_API',
  BLOCK_ZERO_PRICE_OVERRIDE = 'BLOCK_ZERO_PRICE_OVERRIDE',
  VIEWPOINT_MEAL_BREAKOUT_SETTINGS = 'VIEWPOINT_MEAL_BREAKOUT_SETTINGS',
  SMART_LOCK_QR_CODE_ENABLED = 'SMART_LOCK_QR_CODE_ENABLED',
  CARES_LICENSE_OFF = 'CARES_LICENSE_OFF',
  SALES_CHANNEL_TOGGLE = 'SALES_CHANNEL_TOGGLE',
  CATERING_PRICE_OVERRIDES = 'CATERING_PRICE_OVERRIDES',
  TIME_PUNCH = 'TIME_PUNCH',
  ENABLE_CARES = 'ENABLE_CARES',
}

export default FeatureFlags;
